// import auth from '../../middleware/auth'
export const generateRoutes = () => {
        return [
            {
                path: '/dates',
                name: 'dates',
                props: true,
                component: () => import('../../pages/Delivery.vue'),
            },
            {
                path: '/service',
                name: 'service',
                component: () => import('../../pages/RequestType.vue'),
            },
            {
                path: '/complete',
                name: 'complete',
                component: () => import('../../pages/Checkout.vue'),
            },
            {
                path: '/confirmation',
                name: 'confirmation',
                component: () => import('../../pages/Confirmation.vue'),
            },
            {
                path: '/:zipcode',
                name: 'Start',
                props: true,
                component: () => import('../../components/App.vue'),
            },
			{
                path: '/access-denied',
                name: 'denied',
                component: () => import('../../pages/Denied.vue'),
            },
        ]
}
