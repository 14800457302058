<template>
    <SpinnerModal
        v-if="showSpinner"
        :showSpinner="showSpinner"
        :timeOutFunc="displayNotFoundModal"
    />
    <InputModal
        v-if="showInputModal"
        :showInputModal="showInputModal"
        :toggleInputModal="toggleInputModal"
        :changeZipcode="changeZipcode"
        :displaySpinner="displaySpinner"
        :serviceType="serviceType"
        :allowInputClose="allowInputClose"
        :closeInputModal="closeInputModal"
    />
    <NotFoundModal
        v-if="showNotFoundModal"
        :showNotFoundModal="showNotFoundModal"
        :toggleNotFoundModal="toggleNotFoundModal"
        :serviceType="serviceType"
        :locationErrorMsg="locationErrorMsg"
    />
    <div class='2xl:px-4 overflow-x-hidden overflow-y-auto sm:pr-4'>
        <div class="flex p-6 w-full sm:fixed sm:bg-white sm:z-10">
            <a
                class="w-1/2 sm:w-36 object-contain sm:mb-0 mb-4"
                href="https://www.bissell.com/"
                target="_blank"
            >
                <img
                    alt="Bissell Logo"
                    class="object-contain"
                    src="../pages/assets/logo-bissell.svg"
                />
            </a>
            <div
                class="pl-4 my-auto cursor-pointer underline text-blue-300"
                @click.prevent="handleZipCodeChange()"
            >Change ZIP code</div>
        </div>
        <!-- Tabs -->
        <div class="w-full sm:fixed sm:mt-32 z-10 sm:bg-white 2xl:pr-6">
            <div class="relative z-0  flex flex-col sm:flex-row ">
                <transition-group name="tab-list">
                    <div
                        v-for="(tab, index) in tabs"
                        :key="tab.name"
                        class="flex flex-col w-full sm:h-32 2xl:h-[150px] cursor-pointer"
                    >
                        <div
                            id="tabs"
                            @click='handleTabs(index)'
                            :class="[index === currentTabIndex ? ' bg-rd_red text-white h-[114px] ' : 'h-[68px] bg-rd_gray text-white hover:bg-hover_red', ' hover:cursor-pointer transform duration-700 ease-in-out  md:mx-2 relative min-w-0  text-center border-b md:border-b-0 sm:rounded-lg']"
                        >
                            <div class='flex flex-row justify-start items-center px text-left items-left h-full pl-8'>
                                <h1 :class="[index === currentTabIndex? 'text-rd_fontsize-56 font-bold ' :'text-rd_fontsize-28 ', 'flex mr-4 items-left text-left' ]">
                                    {{index+1}}
                                </h1>
                                <div :class="[index === currentTabIndex ? 'text-rd_fontsize-20  text-left  font-bold' :'text-rd_fontsize-18 ', 'flex  flex-col text-left ' ]">
                                    <span>
                                        {{ tab.name }}
                                    </span>
                                    <span
                                        v-if="index===0 && selectionForm?.delivery?.day"
                                        class="text-rd_fontsize-14 "
                                    >
                                        {{ selectionForm?.delivery?.day?.dayName +","}}
                                        <span>{{ selectionForm?.delivery?.day?.monthName?.slice(0, 3) + " " +selectionForm?.delivery?.day?.dayNumber +" "}}</span>
                                        <span v-if="selectionForm?.delivery?.time">{{ selectionForm?.delivery?.time?.hours}}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!-- Mobile View -->
                        <div
                            v-if="mobileView === true && index === currentTabIndex"
                            class='inline-block sm:hidden w-full'
                        >
                            <RouterView
                                :selectionForm="selectionForm"
                                :datesArray="datesArray"
                                :calendarToggle="calendarToggle"
                                :timeOfDayOptions="timeOfDayOptions"
                                :apiForm="apiForm"
                                :customer="customer"
                                :confirmation="confirmation"
                                :productPageErrorMsg="productPageErrorMsg"
                                :checkoutPageErrorMsg="checkoutPageErrorMsg"
                                :currentIndex="currentIndex"
                                :zipcode="zipcode"
                                :couponErrorMsg="couponErrorMsg"
                                :displaySpinner="displaySpinner"
                                :nextTab="nextTab"
                                :setCurrentTab="setCurrentTab"
                                :serviceType="serviceType"
                                :tabs="tabs"
                                @handleTabs="handleTabs"
                                :dayLimit="dayLimit"
                                :kiosk_id="kiosk_id"
                                :product_id="product_id"
                                :mobileView="mobileView"
                                :updateOrderError="updateOrderError"
                                :holidays="holidays"
                                :timeslots="timeslots"
                                :customerCountry="customerCountry"
                                :selectedService="selectedService"
                                :customerServiceAgent="customerServiceAgent"
                                :handleCountry="handleCountry"
                                :handleService="handleService"
                                :prefilledForm="prefilledForm"
								:dateObj="dateObj"
                            />
                        </div>
                    </div>
                </transition-group>
            </div>
        </div>
        <!-- Desktop View -->
        <div
            v-if="!mobileView"
            class='hidden sm:inline-block lg:py-6 lg:px-0 w-full mt-60'
        >
            <RouterView
                :selectionForm="selectionForm"
                :datesArray="datesArray"
                :calendarToggle="calendarToggle"
                :timeOfDayOptions="timeOfDayOptions"
                :apiForm="apiForm"
                :customer="customer"
                :confirmation="confirmation"
                :productPageErrorMsg="productPageErrorMsg"
                :checkoutPageErrorMsg="checkoutPageErrorMsg"
                :currentIndex="currentIndex"
                :zipcode="zipcode"
                :couponErrorMsg="couponErrorMsg"
                :displaySpinner="displaySpinner"
                :tabs="tabs"
                :nextTab="nextTab"
                :setCurrentTab="setCurrentTab"
                :serviceType="serviceType"
                @handleTabs="handleTabs"
                :dayLimit="dayLimit"
                :kiosk_id="kiosk_id"
                :product_id="product_id"
                :mobileView="mobileView"
                :updateOrderError="updateOrderError"
                :holidays="holidays"
                :timeslots="timeslots"
                :customerCountry="customerCountry"
                :selectedService="selectedService"
                :customerServiceAgent="customerServiceAgent"
                :handleCountry="handleCountry"
                :handleService="handleService"
                :prefilledForm="prefilledForm"
				:dateObj="dateObj"
            />
        </div>
    </div>
</template>
<script>
    import { useStore, mapGetters, mapActions } from 'vuex'
    import { ref, watch, computed } from 'vue'
	import axios from 'axios'
    import { useRoute } from 'vue-router'
    import InputModal from '../pages/modals/InputModal.vue'
    import NotFoundModal from '../pages/modals/NotFound.vue'
    import SpinnerModal from '../pages/modals/Spinner.vue'
    import LoadingModal from '../pages/modals/LoadingModal.vue'
    import _ from "lodash"
    import { getCountry, getState } from '../pages/forms/getLocation'

    export default {
        components: {
            InputModal,
            NotFoundModal,
            SpinnerModal,
            LoadingModal,
        },
        setup() {
            console.log('setup')
            const userLanguage = window.navigator.userLanguage || window.navigator.language
            const route = useRoute()
            const store = useStore()
            const selectionForm = computed(() => store.getters['checkout/form'])
            const tabs = computed(() => store.getters['checkout/tabs'])
            const datesArray = computed(() => store.getters['checkout/datesArray'])
            const calendarToggle = computed(() => store.getters['checkout/calendarToggle'])


            const timeOfDayOptions = computed(() => store.getters['checkout/timeOfDayOptions'])

            const zipcode = computed(() => store.getters['checkout/zipcode'])



            const apiForm = computed(() => store.getters['checkout/apiForm'])
            const customer = computed(() => store.getters['checkout/customer'])
            const confirmation = computed(() => store.getters['checkout/confirmation'])


            const productPageErrorMsg = computed(() => store.getters['checkout/productPageErrorMsg'])
            const checkoutPageErrorMsg = computed(() => store.getters['checkout/checkoutPageErrorMsg'])
            const initialLoad = computed(() => store.getters['checkout/initialLoad'])
            const currentIndex = computed(() => store.getters['checkout/currentIndex'])
            const couponErrorMsg = computed(() => store.getters['checkout/couponErrorMsg'])

            const mobileView = window.innerWidth >= 640 ? ref(false) : ref(true)
            const initLoad = ref(false)
            const showInputModal = ref(false)
            const showNotFoundModal = ref(false)
            const showSpinner = ref(true)

            const datesLoaded = ref(false)

            const currentTabIndex = computed(() => store.getters['checkout/currentTabIndex'])
            const jdeNumber = computed(() => store.getters['checkout/jdeNumber'])
            const kiosk_id = computed(() => store.getters['checkout/kiosk_id'])
            const product_id = computed(() => store.getters['checkout/product_id'])
            const locationErrorMsg = computed(() => store.getters['checkout/locationErrorMsg'])
            const deliveryErrorMsg = computed(() => store.getters['checkout/deliveryErrorMsg'])
            const updateOrderError = computed(() => store.getters['checkout/updateOrderError'])


            const serviceType = 'delivery'
            const dayLimit = ref(28)

            const showLoadingModal = ref(false)
            const holidays = computed(() => store.getters['checkout/holidays'])
            const timeslots = computed(() => store.getters['checkout/timeslots'])

            const customerServiceAgent = computed(() => store.getters['checkout/customerServiceAgent'])
            const customerCountry = computed(() => store.getters['checkout/customerCountry'])
            const selectedService = computed(() => store.getters['checkout/selectedService'])

            const prefilledForm = computed(() => store.getters['checkout/prefilledForm'])
            const allowInputClose = ref(false)
			const dateObj = computed(() => store.getters['checkout/dateObj'])

            return {
                route,
                store,
                selectionForm,
                tabs,
                datesArray,
                calendarToggle,

                timeOfDayOptions,
                allowInputClose,

                zipcode,
                customer,
                confirmation,
                mobileView,
                apiForm,
                productPageErrorMsg,
                checkoutPageErrorMsg,
                initLoad,
                initialLoad,
                currentIndex,
                couponErrorMsg,
                showInputModal,
                showNotFoundModal,
                showSpinner,
                datesLoaded,
                currentTabIndex,
                serviceType,
                jdeNumber,
                dayLimit,
                kiosk_id,
                product_id,
                locationErrorMsg,
                deliveryErrorMsg,
                updateOrderError,

                showLoadingModal,
                holidays,
                timeslots,

                customerServiceAgent,
                customerCountry,
                selectedService,
                prefilledForm,
				dateObj,
            }
        },
        mounted() {
            window.addEventListener('resize', this.onResize)
            if (this.route?.path === '/access-denied') {
                this.showInputModal = false
                this.$router.push({ name: 'denied' })
                return
            } 
			else {

                // if (this.route.params.zipcode) {
                //     this.showSpinner = true
                //     this.$store.dispatch('checkout/setInitialLoad')
                //     this.$store.dispatch('checkout/setZipcode', this.route.params.zipcode)
                //     this.$router.push({ name: this.tabs[0].href })
                //     this.$store.dispatch('checkout/setCurrentTabIndex', 0)

                // }
                // else if (this.zipcode) {
                //     this.$store.dispatch('checkout/setInitialLoad')
                //     this.$router.push({ name: this.tabs[this.currentTabIndex || 0].href })
                //     this.$store.dispatch('checkout/setCurrentTabIndex', this.currentTabIndex || 0)

                // }
                // else if (this.deliveryErrorMsg !== null) {
                //     this.showInputModal = true
                //     this.showSpinner = false
                // }
                // else if (this.zipcode === null) {
                // 	console.log('mounted else if zipcode')
                // 	this.showInputModal = true
                // 	this.showSpinner = false
                // }

                if (this.datesArray.length > 0) {
                    this.showSpinner = false
                    this.showInputModal = false
                }
            }

        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize)
        },
        watch: {
            $route: function (newVal, oldVal) {
				console.log('newVal: ', newVal)
                if (newVal?.path === '/access-denied') {
                    this.showInputModal = false
                    this.$router.push({ name: 'denied' })
                    return
                } else {
					if (newVal?.path === '/agent') {
						console.log('agent', newVal.query?.zip)
                        const agentTabs = [
                            { index: 0, name: 'Schedule Service', href: 'dates', current: true },
                            { index: 1, name: 'Choose Service Request', href: 'service', current: false },
                            { index: 2, name: 'Submit Request', href: 'complete', current: false },
                        ]
                        this.$store.dispatch('checkout/setTabs', agentTabs)
                        this.$store.dispatch('checkout/setAgent', true)
						if (newVal.query && !_.isEmpty(newVal.query) && !newVal.query?.zip) {
							console.log('if agent !zip', newVal.query?.zip)
                            this.showSpinner = false
                            this.showInputModal = true
                        }
						this.$router.push({ name: agentTabs[0].href })
                        
                    }
                    if (newVal.query && !_.isEmpty(newVal.query)) {
                        const prefilledForm = {
                            first_name: '',
                            last_name: '',
                            email: '',
                            phone: '',
                            incident_number: '',
                            serial_number: '',
                            model_number: '',
                            reference_number: '',
                            street_address1: '',
                            street_address2: '',
                            city: '',
                            state: '',
                            zip: '',
                        }
						if(newVal.query?._data){
							// this.$store.dispatch('checkout/getPrefilledForm', newVal.query._data)
							this.handlePrefilledForm(newVal.query._data)
						}
						else {
							if (newVal.query.first_name) {
                            prefilledForm.first_name = newVal.query.first_name
                        }
                        if (newVal.query.last_name) {
                            prefilledForm.last_name = newVal.query.last_name
                        }
                        if (newVal.query.email) {
                            prefilledForm.email = newVal.query.email
                        }
                        if (newVal.query.phone) {
                            prefilledForm.phone = newVal.query.phone
                        }
                        if (newVal.query.incident_number) {
                            prefilledForm.incident_number = newVal.query.incident_number
                        }
                        if (newVal.query.serial_number) {
                            prefilledForm.serial_number = newVal.query.serial_number
                        }
                        if (newVal.query.model_number) {
                            prefilledForm.model_number = newVal.query.model_number
                        }
                        if (newVal.query.street_address1) {
                            let street_address1 = newVal.query.street_address1.replace(/_/g, ' ')
                            prefilledForm.street_address1 = street_address1
                        }
                        if (newVal.query.street_address2) {
                            let street_address2 = newVal.query.street_address2.replace(/_/g, ' ')
                            prefilledForm.street_address2 = street_address2
                        }
                        if (newVal.query.city) {
                            let city = newVal.query.city.replace(/_/g, ' ')
                            prefilledForm.city = city
                        }
                        if (newVal.query.reference_number) {
                            prefilledForm.reference_number = newVal.query.reference_number
                        }
                        if (newVal.query.state) {
                            prefilledForm.state = newVal.query.state
                        }
                        if (newVal.query.province) {
                            prefilledForm.state = newVal.query.province
                            prefilledForm.country = 'Canada'
                            this.handleCountry('Canada')
                        } 
						else {
                            prefilledForm.country = 'United States'
                            this.handleCountry('United States')
                        }
						if (newVal.query.zip) {
                            prefilledForm.zip = newVal.query.zip
                        } 
						else if (!newVal.query?.zip) {
                            this.showSpinner = false
                            this.showInputModal = true
                        }

                        this.$store.dispatch('checkout/setPrefilledForm', prefilledForm)
						}


						

                    }
                    
                    if (newVal.query?.zip) {
						console.log('if newVal.query?.zip ')
                        let newValZip = newVal.query.zip

                        this.showInputModal = false
                        this.showSpinner = true
                        this.$store.dispatch('checkout/setInitialLoad')

						this.changeZipcode(newValZip)
                    }
                    else if (newVal.query?.zip && this.initialLoad === false) {
                        this.showInputModal = false
                        this.showSpinner = true
                        this.$store.dispatch('checkout/setInitialLoad')
                        this.$store.dispatch('checkout/resetForm')
                        this.$store.dispatch('checkout/resetDates')

						this.changeZipcode(newVal.query?.zip)
                    }
                    else if (newVal.fullPath === "/") {
						console.log('full path /')
                        this.showSpinner = false
                        this.showInputModal = true
                        if (this.initialLoad === false) {
							console.log('initialLoad false ')
                            this.$store.dispatch('checkout/setInitialLoad')
                            this.$router.push({ name: this.tabs[0].href })
                            this.$store.dispatch('checkout/setCurrentTabIndex', 0)
                        }
                    }
                }

            },
            datesArray: function (newVal, oldVal) {
                if (!_.isEmpty(newVal)) {
                    if (this.serviceType === 'delivery') {
						console.log('this.serviceType === delivery')
                        const anyAvailableDates = newVal.find(d => d.available === true)
                        if (anyAvailableDates) {
                            this.datesLoaded = true
                            this.checkLoad()
                        }
                    }
                    else {
						console.log('datesArray else')
                        this.datesLoaded = true
                        this.checkLoad()
                    }

                }
            },
            deliveryErrorMsg: function (newVal, oldVal) {
                if (newVal) {
                    this.showSpinner = false
                    this.showNotFoundModal = true
                }
            },
            locationErrorMsg: function (newVal, oldVal) {
                if (newVal) {
                    this.showSpinner = false
                    this.showNotFoundModal = true
                }
            },
        },
        methods: {
            ...mapActions('checkout', [
                'setTabs', 'setApiData', 'getData', 'setWeek', 'resetDates', 'resetForm', 'getDates', 'setZipcode', 'setInitialLoad', 'setCurrentTabIndex', 'getReservationData', 'getHolidays', 'getTimeSlots', 'setCustomerCountry', 'setAgent', 'setSelectedService', 'setPrefilledForm', 'getPrefilledForm'
            ]),
            onResize() {
                if (window.innerWidth < 640) {
                    this.mobileView = true
                }
                else if (window.innerWidth >= 640) {
                    this.mobileView = false
                }
            },
            handleTabs(tabIndex, checkout) {
                if (checkout) {
                    const targetTab = this.tabs[tabIndex]
                    const targetTabIndex = this.tabs.indexOf(targetTab)

                    this.$store.dispatch('checkout/setCurrentTabIndex', targetTabIndex)
                    this.$router.push({ name: targetTab.href })
                }
                else if (this.tabValidation(location.hostname, tabIndex)) {
                    const targetTab = this.tabs[tabIndex]
                    const targetTabIndex = this.tabs.indexOf(targetTab)

                    this.$store.dispatch('checkout/setCurrentTabIndex', targetTabIndex)
                    this.$router.push({ name: targetTab.href })
                }
                else {
                    return
                }
            },
            nextTab() {
                const nextTabSelected = this.tabs[this.currentTabIndex + 1]
                if (nextTabSelected?.href) {
                    // this.$store.dispatch('checkout/setCurrentTabIndex', (this.currentTabIndex + 1))
                    this.$router.push({ name: nextTabSelected.href })
                }
            },
            setCurrentTab(index) {
                this.$store.dispatch('checkout/setCurrentTabIndex', index)
            },
            tabValidation(hostname, tabIndex) { // checks if certain conditions are met that are dependent on the host.name
                return (this.selectionForm?.delivery?.time && this.selectionForm?.delivery?.day)
            },
            toggleInputModal() {
                this.showInputModal = !this.showInputModal
            },
            toggleNotFoundModal() {
                this.showNotFoundModal = !this.showNotFoundModal
                this.showInputModal = true
            },
            displaySpinner(show) {
                this.showSpinner = show
            },
            displayNotFoundModal() {
                this.showSpinner = false
                this.showNotFoundModal = true
            },
            changeZipcode(zipcode) {
                let zip = zipcode.replace(/\s/g, '')
                this.allowInputClose = false
                this.$store.dispatch('checkout/getDates', zip)
                
        
                this.$router.push({ name: this.tabs[0].href })
                this.$store.dispatch('checkout/setCurrentTabIndex', 0)
            },
            checkLoad() { //checks if data has loaded
                if (this.datesLoaded === true) {
                    this.showSpinner = false
                    this.showInputModal = false
                    this.showNotFoundModal = false
                    return true
                }
                else {
					console.log('check load')
                    this.showInputModal = false
                    this.showSpinner = true
                    return false
                }
            },
            handleCountry(country) {
                this.$store.dispatch('checkout/setCustomerCountry', country)
            },
            handleAgent(agent) {
                this.$store.dispatch('checkout/setAgent', agent)
            },
            handleService(selectedService) {
                this.$store.dispatch('checkout/setSelectedService', selectedService)
            },
            handleZipCodeChange() {
                this.allowInputClose = true
                this.showInputModal = true
            },
            closeInputModal() {
                this.allowInputClose = false
                this.showInputModal = false
            },
			async handlePrefilledForm(form){
				let formData = form.replace(/\s/g, '+')
				const apiUrl1 = process.env.MIX_API_URL + '/support/decrypt-params'
				const requestOne = axios({
					url: apiUrl1,
					method: 'POST',
					timeout: 10000,
					headers: {
						'Content-Type': 'text/plain'
					},
					data: formData, 
					
				})

				axios.all([requestOne]).then(axios.spread((...responses) => {

					const responseOne = responses[0]
					const prefilledForm = {
						first_name: '',
						last_name: '',
						email: '',
						phone: '',
						incident_number: '',
						serial_number: '',
						model_number: '',
						reference_number: '',
						street_address1: '',
						street_address2: '',
						city: '',
						state: '',
						zip: '',
					}
					if (responseOne?.data) {

						if (responseOne?.data.first_name) {
							prefilledForm.first_name = responseOne?.data.first_name
						}
						if (responseOne?.data.last_name) {
							prefilledForm.last_name = responseOne?.data.last_name
						}
						if (responseOne?.data.email) {
							prefilledForm.email = responseOne?.data.email
						}
						if (responseOne?.data.phone) {
							prefilledForm.phone = responseOne?.data.phone
						}
						if (responseOne?.data.incident_number) {
							prefilledForm.incident_number = responseOne?.data.incident_number
						}
						if (responseOne?.data.serial_number) {
							prefilledForm.serial_number = responseOne?.data.serial_number
						}
						if (responseOne?.data.model_number) {
							prefilledForm.model_number = responseOne?.data.model_number
						}
						if (responseOne?.data.street_address1) {
							let street_address1 = responseOne?.data.street_address1.replace(/_/g, ' ')
							prefilledForm.street_address1 = street_address1
						}
						if (responseOne?.data.street_address2) {
							let street_address2 = responseOne?.data.street_address2.replace(/_/g, ' ')
							prefilledForm.street_address2 = street_address2
						}
						if (responseOne?.data.city) {
							let city = responseOne?.data.city.replace(/_/g, ' ')
							prefilledForm.city = city
						}
						if (responseOne?.data.reference_number) {
							prefilledForm.reference_number = responseOne?.data.reference_number
						}
						if (responseOne?.data.state) {
							prefilledForm.state = responseOne?.data.state
						}
						if (responseOne?.data.province) {
							prefilledForm.state = responseOne?.data.province
							prefilledForm.country = 'Canada'
						} 
						else {
							prefilledForm.country = 'United States'
						}
						if (responseOne?.data.zip) {
							console.log('zip', responseOne?.data.zip)
							prefilledForm.zip = responseOne?.data.zip
							
							this.showInputModal = false
							this.showSpinner = true
							this.$store.dispatch('checkout/setInitialLoad')

							this.changeZipcode(responseOne?.data.zip)
						} 
						else {
							this.showSpinner = false
							this.showInputModal = true
						}
						this.$store.dispatch('checkout/setPrefilledForm', prefilledForm)
					}
				})).catch(errors => {
					console.log(errors)
				})
			}
        }

    };
</script>
