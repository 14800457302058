// import router from "../../router/index"
// https://dev.api.rapidrent.com/venues?zip=75075
import { getFutureDate, formatDates, GetDates, MonthNumber, MonthAsString, DayAsString, formatDateTime, formatTimeSlots, convertPreferredTime } from "../helpers/Dates"
import axios from 'axios'
export const namespaced = true

// STATE
export const state = {
	customerCountry: null,
	customerServiceAgent: false,
	selectedService: null,
	initialLoad: false,

	zipcode: null,

	currentIndex: -1,
	apiData: null,
	apiForm: null,
	confirmation: null,

	customer: {},
	tabs:
		[
			{ index: 0, name: 'Schedule Service', href: 'dates', current: true },
			{ index: 1, name: 'Submit Request', href: 'complete', current: false },
		],
	currentTabIndex: 0,
	selectionForm: { // FORM
		delivery: {
			day: null,
			time: null,
		},
		pickup: {
			day: null,
			time: null,
		},
	},
	deliveryDay: null,
	pickupDay: null,
	timeOfDayOptions: [
		{ timeOfDay: 'MORNING', hours: '8:00am-12:00pm', checked: false, delivery: false, pickup: false },
		{ timeOfDay: 'AFTERNOON', hours: '1:00pm-5:00pm', checked: false, delivery: false, pickup: false },
	],

	datesArray: [], //DATES
	calendarToggle: false,
	dateObj: {},
	productPageErrorMsg: null,
	checkoutPageErrorMsg: null,
	couponErrorMsg: null,
	locationErrorMsg: null,
	deliveryErrorMsg: null,
	updateOrderError: null,
	jdeNumber: null,
	kiosk_id: null,
	product_id: null,

	holidays: [],
	timeslots: [],
	prefilledForm: {
		first_name: '',
		last_name: '',
		email: '',
		phone: '',
		incident_number: '',
		serial_number: '',
		reference_number: '',
		model_numer: '',
		street_address1: '',
		street_address2: '',
		city: '',
		state: '',
		zip: '',
		country: '',	
	}
}

export const mutations = {
	//API
	SET_APIDATES(state, data) {
		state.apiDates = data
	},
	SET_APIFORM(state, data) {
		state.apiForm = data
	},
	FILTER_APIDATA(state, resp) { // Home Delivery

		state.apiData = []

	},

	SET_HOLIDAYS(state, resp) {
		let data = JSON.parse(JSON.stringify(resp))
		const holidayList = []
		if (Array.isArray(data) && data.length > 0) {
			data.forEach(d => {
				if (d.holiday_date) {
					d.fullDate = new Date(d.holiday_date)
					holidayList.push(new Date(d.fullDate.toISOString().slice(0, -1)))
				}
			})
		}

		state.holidays = holidayList
	},
	SET_TIME_SLOTS(state, resp) {
		let data = JSON.parse(JSON.stringify(resp))
		let timeslotsList = []
		if (Array.isArray(data) && data.length > 0) {
			timeslotsList = formatTimeSlots(data)
			state.timeOfDayOptions = timeslotsList
		}
		state.timeslots = timeslotsList
	},

	SET_CONFIRMATION(state, data) {
		state.confirmation = data
	},
	SET_ERROR_MSG(state, obj) {
		state[obj.page] = obj.message
	},
	SET_COUPON_MSG(state, message) {
		state.couponErrorMsg = message
	},
	SET_KIOSK_ID(state, kiosk_id) {
		state.kiosk_id = kiosk_id
	},
	SET_PRODUCT_ID(state, product_id) {
		state.product_id = product_id
	},
	//CUSTOMER
	SET_CUSTOMER(state, customer) {
		state.customer = customer
	},
	//TAB CONTROL
	SET_TABS(state, tabs) {
		state.tabs = tabs
	},
	SET_CURRENTINDEX(state, currentIndex) {
		state.currentIndex = currentIndex
	},
	
	//FORM
	SET_FORM(state, selectionForm) {
		state.selectionForm = selectionForm
	},
	RESET_FORM(state) {
		state.selectionForm = {
			delivery: {
				day: null,
				time: null,
			},
			pickup: {
				day: null,
				time: null,
			},
		}
		state.calendarToggle = false
		state.apiData = null
		state.apiForm = null
		state.confirmation = null
		state.timeOfDayOptions = [
			{ timeOfDay: 'MORNING', hours: '8:00am-12:00pm', checked: false, delivery: false, pickup: false },
			{ timeOfDay: 'AFTERNOON', hours: '1:00pm-5:00pm', checked: false, delivery: false, pickup: false },
		]
		state.productPageErrorMsg = null
		state.checkoutPageErrorMsg = null
		state.confirmation = null

		state.jdeNumber = null
		state.kiosk_id = null
		state.product_id = null

		state.datesArray = []
		state.zipcode = null
		state.selectedService = null

	},

	//DATES
	SET_TIMEOFDAY(state, time) {

		if (time.scheduledDay === 'delivery') {
			state.selectionForm.delivery.time = time
			state.timeOfDayOptions[0].delivery = false
			state.timeOfDayOptions[1].delivery = false

			//Resets pickup
			state.timeOfDayOptions[0].pickup = false
			state.timeOfDayOptions[1].pickup = false
			state.selectionForm.pickup.day = null
			state.selectionForm.pickup.time = null

			if (time.timeOfDay.toLowerCase() === "morning") {
				state.timeOfDayOptions[0].delivery = true
			} else {// "afternoon"
				state.timeOfDayOptions[1].delivery = true
			}

		}
		else { //"pickup"
			state.selectionForm.pickup.time = time
			state.timeOfDayOptions[0].pickup = false
			state.timeOfDayOptions[1].pickup = false
			if (time.timeOfDay.toLowerCase() === "morning") {
				state.timeOfDayOptions[0].pickup = true
			} else {// "afternoon"
				state.timeOfDayOptions[1].pickup = true
			}
		}
	},
	SET_DAY(state, day) {
		const deliveryCheckedItems = state.datesArray.filter(item => item.delivery === true)
		const findDay = state.datesArray.find(item => item.fullDate === day.fullDate)
		if (deliveryCheckedItems.length > 0) {
			deliveryCheckedItems.forEach(e => e.delivery = false)
		}
		findDay.delivery = true
		state.selectionForm.delivery.day = findDay
		state.selectionForm.delivery.time = null
		// calendar highlight off
		state.calendarToggle = false
	},
	SET_DAY_CALENDAR(state, day) { //Sets date from calendar

		let selectedDate = new Date(day)
		let dateObj = { //OBJs that get passed to datesArray
			dayName: DayAsString(selectedDate.getDay()),
			dayNumber: selectedDate.getDate(),
			monthNumber: parseInt(MonthNumber(selectedDate.getMonth())),
			monthName: MonthAsString(selectedDate.getMonth()),
			year: selectedDate.getFullYear(),
			delivery: false,
			pickup: false,
			fullDate: selectedDate
		}

		state.selectionForm.delivery.day = dateObj
		//Resets pickup & delivery
		state.datesArray.forEach(item => {
			item.delivery = false
			item.pickup = false
		})
		const findDay = state.datesArray.find(item => item.dayNumber === dateObj.dayNumber && item.monthNumber === dateObj.monthNumber && item.year === dateObj.year)
		if (findDay) {
			findDay.delivery = true
		}
		state.selectionForm.delivery.time = null
		let currentIndex = state.datesArray.findIndex(item => item.dayNumber === dateObj.dayNumber && item.monthNumber === dateObj.monthNumber && item.year === dateObj.year)
		if (currentIndex) {
			state.currentIndex = currentIndex
		}

		state.calendarToggle = true

	},
	SET_WEEK(state, dates) {
		state.datesArray = dates

	},

	RESET_DATES(state) {
		state.timeOfDayOptions[0].pickup = false
		state.timeOfDayOptions[1].pickup = false
		state.selectionForm.pickup.day = null
		state.selectionForm.pickup.time = null

		state.timeOfDayOptions[0].delivery = false
		state.timeOfDayOptions[1].delivery = false
		state.selectionForm.delivery.day = null
		state.selectionForm.delivery.time = null
	},
	SET_CALENDAR_TOGGLE(state) {
		state.calendarToggle = !state.calendarToggle
	},

	SET_ZIPCODE(state, zipcode) {
		state.zipcode = zipcode
	},
	SET_INITIALLOAD(state, zipcode) {
		state.initialLoad = true
	},
	SET_CIRRENTTABINDEX(state, index) {
		state.currentTabIndex = index
	},
	SET_CUSTOMER_COUNTRY(state, country) {
		state.customerCountry = country
	},
	SET_AGENT(state, customerServiceAgent) {
		state.customerServiceAgent = customerServiceAgent
	},
	SET_SELECT_SERVICE(state, selectedService) {
		state.selectedService = selectedService
	},
	SET_AGENT_TABS(state, tabs) {
		state.tabs = tabs
	},
	SET_PREFILLED_FORM(state, form){
		state.prefilledForm = form
	},
	SET_ZIPCODE(state,zipcode){
		state.zipcode = zipcode
	},
	SET_DATE_OBJ(state, obj){
		state.dateObj = obj
	}
}

export const actions = {
	//ZipCode
	async setZipcode({ commit, dispatch }, zipcode) {
		commit("SET_ZIPCODE", zipcode)
	},
	async setInitialLoad({ commit, dispatch }) {
		commit("SET_INITIALLOAD")
	},


	//TABS
	async setTabs({ commit, dispatch }, tabs) {
		commit("SET_TABS", tabs)
	},

	async setCurrentTabIndex({ commit, dispatch }, index) {
		commit("SET_CIRRENTTABINDEX", index)
	},
	//FORM
	async setForm({ commit, dispatch }, selectionForm) {
		commit("SET_FORM", selectionForm)
	},
	async resetForm({ commit, dispatch }) {
		commit("RESET_FORM")
	},
	async setAffiliate({ commit, dispatch }, id) {
		commit("SET_AFFILIATE", id)
	},

	//DATES
	async setTimeOfDay({ commit, dispatch }, time) {
		commit("SET_TIMEOFDAY", time)
	},
	async setDay({ commit, dispatch }, day) {
		commit('SET_DAY', day)
	},
	async setDayCalendar({ commit, dispatch }, day) {
		commit('SET_DAY_CALENDAR', day)
	},
	async setWeek({ commit }) {
		commit('SET_WEEK')
	},

	async resetDates({ commit }) {
		commit('RESET_DATES')
	},
	async setCalendarToggle({ commit }) {
		commit('SET_CALENDAR_TOGGLE')
	},
	async setDateObj({ commit }) {
		commit('SET_DATE_OBJ')
	},

	//API
	async setApiData({ commit, dispatch }, data) {
		commit('SET_APIDATA', data)
	},
	async getDates({ commit, dispatch }, zip) { //Home Delivery
		function addDays(date, days) {
			let result = new Date(date);
			result.setDate(result.getDate() + days);
			return result;
		  }
		const userLanguage = 'English'
		const minDate = new Date(2023, 0, 13)
		const today = new Date()
		let startD
		if(today < minDate){
			startD = addDays(minDate, 3)
		}
		else {
			startD = addDays(today, 3)
		}

		const zipcode = zip ? zip : state.zipcode
		const furtureDate = getFutureDate()
		const startDate = startD.getUTCFullYear() + '-' + (startD.getUTCMonth() + 1) + '-' + startD.getUTCDate()
		const endDate = furtureDate.getUTCFullYear() + '-' + (furtureDate.getUTCMonth() + 1) + '-' + furtureDate.getUTCDate()

		const apiUrl1 = process.env.MIX_API_URL + '/drivers/service-call-availability?' + 'start_date=' + startDate + '&due_date=' + endDate + ' &renter_zip=' + zipcode
		const apiUrl2 = process.env.MIX_API_URL + '/venues/service-call-products-at-zip?renter_zip=' + zipcode
		const apiUrl3 = process.env.MIX_API_URL + '/holiday/service-call-holidays-by-zip?zip='+ zipcode + '&start_date=' + startDate + '&end_date=' + endDate 
		const requestOne = axios({
			url: apiUrl1,
			method: 'get',
			timeout: 100000,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		const requestTwo = axios({
			url: apiUrl2,
			method: 'get',
			timeout: 100000,
			headers: {
				'Content-Type': 'application/json',
			}
		})

		const requestThree = axios({
			url: apiUrl3,
			method: 'get',
			timeout: 100000,
			headers: {
				'Content-Type': 'application/json',
			}
		})

		axios.all([requestOne, requestTwo, requestThree]).then(axios.spread((...responses) => {

			const responseOne = responses[0]
			const responseTwo = responses[1]
			const responseThree = responses[2]

			if (responseOne?.data && responseTwo?.data && responseThree?.data) {

				const slots = responseOne.data
				const items = responseTwo.data
				const holidayDates = responseThree.data
				if(Array.isArray(items) && items.length > 0 && items[0] && items[0].kiosk?.id && items[0].id){
					let item = items.find(e =>  (e.price?.retail_price === 0 || e.product_group === "MACHINE" || e.product_group === "machine"))
					const kiosk_id = item.kiosk.id
					const product_id = item.id
					let datesArray =  formatDates(slots, userLanguage, holidayDates)
					let dateObj = {}
					for(let i = 0; i < datesArray.length; i++){
						let stringDate =datesArray[i]?.stringDate
						if(stringDate){
							 dateObj[stringDate.slice(0,10)] = datesArray[i].available
						}
					}
					console.log('dateObj', dateObj)
					commit("SET_ZIPCODE", zipcode)
					commit('SET_KIOSK_ID', kiosk_id)
					commit('SET_PRODUCT_ID', product_id)
					commit('SET_DATE_OBJ', dateObj)
					commit('SET_APIDATES', datesArray)
					commit('SET_HOLIDAYS', holidayDates)
					commit('SET_WEEK', datesArray)
					
					
				}
			}
		})).catch(errors => {
			console.log(errors)
		})

	},
	async getData({ commit, dispatch }, zip) { //Home Delivery
		commit('SET_ERROR_MSG', { // Resets msg
			message: null,
			page: "deliveryErrorMsg",
		})
		let apiUrl = process.env.MIX_API_URL + '/venues/products-available-at-zip?renter_zip='
		let zipcode = zip ? zip : state.zipcode
		try {
			let res = await axios({

				url: apiUrl + zipcode,
				method: 'get',
				timeout: 100000,
				headers: {
					'Content-Type': 'application/json',
				}
			})
		
			if (Array.isArray(res.data?.products_available_at_zip) && res.data.products_available_at_zip.length > 0) {
				commit('FILTER_APIDATA', res.data)
			}
			else {
				commit('SET_ERROR_MSG', { // Resets msg
					message: 'Sorry, service is not supported in your area.',
					page: "deliveryErrorMsg",
				})
			}


		}
		catch (err) {
			console.error(err)
			commit('SET_ERROR_MSG', { // Resets msg
				message: 'Sorry, service is not supported in your area.',
				page: "deliveryErrorMsg",
			})
		}
	},

	async getApiForm({ commit, state }, obj) { 
		commit('SET_ERROR_MSG', {
			message: null,
			page: "updateOrderError",
		})
		const deliveryDay = obj.selectionForm.delivery.day
		const deliveryTime = obj.selectionForm.delivery.time

		const form = {
			kiosk_id: obj.kiosk_id,
			start_date: formatDateTime(deliveryTime, deliveryDay),
			preferred_delivery_time: convertPreferredTime(deliveryTime.hours),
			preferred_pickup_time: convertPreferredTime(deliveryTime.hours),
			coupon_codes: [],
			products: [
				{
					product_type_id: obj.product_id,
					quantity: 1
				}
			]
		}
		const apiUrl = process.env.MIX_API_URL + '/rentals/' 
		try {
			const resp = await axios.post(apiUrl, form)
			if (resp?.data) {
				commit('SET_APIFORM', resp.data)
			}
		}
		catch (err) {
			console.log(err)
			if (err.response?.data?.message) {

				let errorObj = {
					message: err.response.data.message,
					page: "updateOrderError",
				}
				commit('SET_ERROR_MSG', errorObj)
			}
			else {
				let errorObj = {
					message: "An unknown error has occured",
					page: "updateOrderError",
				}
				commit('SET_ERROR_MSG', errorObj)
			}
			console.error(err)
		}
	},
	async updateRental({ commit, state }, obj) {
		commit('SET_ERROR_MSG', {
			message: null,
			page: "updateOrderError",
		})
		const deliveryDay = obj.selectionForm.delivery.day
		const deliveryTime = obj.selectionForm.delivery.time
		const form = {
			id: obj.rental_id,
			kiosk_id: obj.kiosk_id,
			start_date: formatDateTime(deliveryTime, deliveryDay),
			end_date: formatDateTime(deliveryTime, deliveryDay),
			preferred_delivery_time: convertPreferredTime(deliveryTime.hours),
			preferred_pickup_time: convertPreferredTime(deliveryTime.hours),
			coupon_codes: [],
			products: [
				{
					product_type_id: obj.product_id,
					quantity: 1
				}
			]
		}

		let apiUrl = process.env.MIX_API_URL + '/rentals/' + obj.rental_id
		try {
			const resp = await axios.put(apiUrl, form)

			if (resp?.data) {
				commit('SET_APIFORM', resp.data)
			}

		}
		catch (err) {
			if (err.response?.data?.message) {
				commit('SET_COUPON_MSG', err.response.data.message,)
			}
			else {
				commit('SET_COUPON_MSG', 'Invalid Coupon',)
			}
			console.error('updateRental error', err)

			commit('SET_ERROR_MSG', {
				message: 'error',
				page: "updateOrderError",
			})

		}
	},
	async submitForm({ commit, dispatch }, form) { 
		commit('SET_CUSTOMER', form)
		const apiUrl = process.env.MIX_API_URL + '/rentals/'+ form.rental_id + '/publish'
		try {
			const resp = await axios.put(apiUrl , form)
			if (resp?.data) {
				commit('SET_CONFIRMATION', resp.data)
			}
		}
		catch (err) {
			if (err.response?.data?.message) {
				let errorObj = {
					message: err.response.data.message,
					page: "checkoutPageErrorMsg",
				}
				commit('SET_ERROR_MSG', errorObj)
			} else {
				commit('SET_ERROR_MSG', {
					message: "Please select another date.",
					page: "checkoutPageErrorMsg",
				})
			}
			console.error(err)
		}
	},
	async setCustomer({ commit, dispatch }, customer) {
		commit('SET_CUSTOMER', customer)
	},
	async getHolidays({ commit, dispatch }, zip) {
		let apiUrl = process.env.MIX_API_URL + '/holiday/service-call-holidays-by-zip?'
		let zipcode = zip ? zip : state.zipcode
		try {
			let res = await axios({
				url: apiUrl + 'zip=' + zipcode,
				method: 'get',
				timeout: 100000,
				headers: {
					'Content-Type': 'application/json',
				}
			})
			if (res?.data) {

				commit('SET_HOLIDAYS', res.data)
			}
		}
		catch (err) {
			console.error(err)
		}
	},
	async setCustomerCountry({ commit, dispatch }, country) {
		commit('SET_CUSTOMER_COUNTRY', country)
	},
	async getTimeSlots({ commit, dispatch }, zip) {
		let apiUrl = process.env.MIX_API_URL + '/time_slot/time-slots-by-zip?'
		let zipcode = zip ? zip : state.zipcode
		try {
			let res = await axios({
				url: apiUrl + 'zip=' + zipcode,
				method: 'get',
				timeout: 100000,
				headers: {
					'Content-Type': 'application/json',
				}
			})
			if (res?.data) {

				commit('SET_TIME_SLOTS', res.data)
			}
		}
		catch (err) {
			console.error(err)
		}
	},
	async setAgent({ commit, dispatch }, customerServiceAgent) {
		commit('SET_AGENT', customerServiceAgent)
	},
	async setSelectedService({ commit, dispatch }, selectedService) {
		commit('SET_SELECT_SERVICE', selectedService)
	},
	async getPrefilledForm({ commit, dispatch }, form) {
		console.log('form: ', form)
		// let formData = form.replace(/\s/g, '')
		// let formData = form
		let formData = form.replace(/\s/g, '+')
		// commit('SET_PREFILLED_FORM', prefilledForm)
		const apiUrl1 = process.env.MIX_API_URL + '/support/decrypt-params'
		const requestOne = axios({
			url: apiUrl1,
			method: 'POST',
			timeout: 10000,
			headers: {
				'Content-Type': 'text/plain'
			},
			data: formData, 
			
		})
		// const requestOne = axios.post(apiUrl1, form, {
		// 	headers: { 'Content-Type': 'text/plain' }
		// });
		axios.all([requestOne]).then(axios.spread((...responses) => {

			const responseOne = responses[0]
			const prefilledForm = {
				first_name: '',
				last_name: '',
				email: '',
				phone: '',
				incident_number: '',
				serial_number: '',
				model_number: '',
				reference_number: '',
				street_address1: '',
				street_address2: '',
				city: '',
				state: '',
				zip: '',
			}
			if (responseOne?.data) {

				console.log('getForm response',responseOne?.data)
				if (responseOne?.data.first_name) {
					prefilledForm.first_name = responseOne?.data.first_name
				}
				if (responseOne?.data.last_name) {
					prefilledForm.last_name = responseOne?.data.last_name
				}
				if (responseOne?.data.email) {
					prefilledForm.email = responseOne?.data.email
				}
				if (responseOne?.data.phone) {
					prefilledForm.phone = responseOne?.data.phone
				}
				if (responseOne?.data.incident_number) {
					prefilledForm.incident_number = responseOne?.data.incident_number
				}
				if (responseOne?.data.serial_number) {
					prefilledForm.serial_number = responseOne?.data.serial_number
				}
				if (responseOne?.data.model_number) {
					prefilledForm.model_number = responseOne?.data.model_number
				}
				if (responseOne?.data.street_address1) {
					let street_address1 = responseOne?.data.street_address1.replace(/_/g, ' ')
					prefilledForm.street_address1 = street_address1
				}
				if (responseOne?.data.street_address2) {
					let street_address2 = responseOne?.data.street_address2.replace(/_/g, ' ')
					prefilledForm.street_address2 = street_address2
				}
				if (responseOne?.data.city) {
					let city = responseOne?.data.city.replace(/_/g, ' ')
					prefilledForm.city = city
				}
				if (responseOne?.data.reference_number) {
					prefilledForm.reference_number = responseOne?.data.reference_number
				}
				if (responseOne?.data.state) {
					prefilledForm.state = responseOne?.data.state
				}
				if (responseOne?.data.province) {
					prefilledForm.state = responseOne?.data.province
					prefilledForm.country = 'Canada'
				} 
				else {
					prefilledForm.country = 'United States'
				}
				if (responseOne?.data.zip) {
					console.log('zip', responseOne?.data.zip)
					prefilledForm.zip = responseOne?.data.zip
					dispatch('getDates', responseOne?.data.zip);
					// this.getDates(responseOne?.data.zip)
				} 
				commit('SET_PREFILLED_FORM', prefilledForm)
			}
		})).catch(errors => {
			console.log(errors)
		})

	},
	async setPrefilledForm({ commit, dispatch }, prefilledForm) {
		commit('SET_PREFILLED_FORM', prefilledForm)
	},
}

export const getters = {
	// API
	apiData: (state) => {
		return state.apiData
	},
	apiForm: (state) => {
		return state.apiForm
	},
	//Error Messages
	productPageErrorMsg: (state) => {
		return state.productPageErrorMsg
	},
	checkoutPageErrorMsg: (state) => {
		return state.checkoutPageErrorMsg
	},
	couponErrorMsg: (state) => {
		return state.couponErrorMsg
	},
	locationErrorMsg: (state) => {
		return state.locationErrorMsg
	},
	deliveryErrorMsg: (state) => {
		return state.deliveryErrorMsg
	},
	updateOrderError: (state) => {
		return state.updateOrderError
	},


	// TABS
	tabs: (state) => {
		return state.tabs
	},
	currentIndex: (state) => {
		return state.currentIndex
	},
	currentTabIndex: (state) => {
		return state.currentTabIndex
	},
	// FORMS
	form: (state) => {
		return state.selectionForm
	},
	selectedService: (state) => {
		return state.selectedService
	},

	// DATES
	datesArray: (state) => {
		return state.datesArray
	},
	timeOfDayOptions: (state) => {
		return state.timeOfDayOptions
	},
	calendarToggle: (state) => {
		return state.calendarToggle
	},
	holidays: (state) => {
		return state.holidays
	},
	timeslots: (state) => {
		return state.timeslots
	},



	// LOCATION
	zipcode: (state) => {
		return state.zipcode
	},
	initialLoad: (state) => {
		return state.initialLoad
	},
	//Agent
	customerServiceAgent: (state) => {
		return state.customerServiceAgent
	},
	// CUSTOMER
	customer: (state) => {
		return state.customer
	},
	customerCountry: (state) => {
		return state.customerCountry
	},
	// CONFIRMATION
	confirmation: (state) => {
		return state.confirmation
	},
	jdeNumber: (state) => {
		return state.jdeNumber
	},
	kiosk_id: (state) => {
		return state.kiosk_id
	},
	product_id: (state) => {
		return state.product_id
	},
	prefilledForm: (state) => {
		return state.prefilledForm
	},
	dateObj: (state) => {
		return state.dateObj
	}
}


