
<template>
    <TransitionRoot
        as="template"
        :show="showNotFoundModal"
    >
        <Dialog
            as="div"
            class="fixed z-50 inset-0 overflow-y-auto"
        >
            <div class="flex items-end justify-center min-h-screen  pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>
                <span
                    class="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >&#8203;</span>
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div class="inline-block h-max max-w-6xl align-bottom  text-left  transform transition-all sm:align-middle">
                        <div
                            v-if="needHelpPrompt"
                            class="w-full h-full items-center font-thin font-open_sans text-lg xl:text-fs_headline_div bg-white  p-8 rounded-lg"
                        >
                            <div class="relative">
                                <span class="absolute right-0 top-0">
                                    <img
                                        @click.prevent="handleNeedHelp(false)"
                                        class="z-10 object-contain h-[18px] w-[18px] ml-auto"
                                        src="../assets/close-line.png"
                                    />
                                </span>
                            </div>
                            <div class="text lg:text-rd_fontsize-32  pt-2 md:pt-4">
                                <div class="flex flex-col gap-16">
                                    <span>Need some Assistance in Scheduling your BISSELL Service Appointment?</span>
                                    <span class="flex flex-col md:flex-row">
                                        <span>Give our agents a call at<span>&nbsp;</span></span>
                                        <span v-if="userCountry === 'United States'">
                                            <a
                                                class="flex flex-row"
                                                href="tel:+8554177001"
                                            >1-855-417-7001
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="white"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="1.5"
                                                    stroke="currentColor"
                                                    class="h-5 w-5 md:h-10 md:w-10 md:p-2 my-auto bg-green-300 rounded-lg"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                                                    />
                                                </svg>

                                            </a>
                                        </span>
                                        <span v-else-if="userCountry === 'Canada'">
                                            <a
                                                class="flex flex-row"
                                                href="tel:+8557391702"
                                            >1-855-739-1702
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="white"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="1.5"
                                                    stroke="currentColor"
                                                    class="h-5 w-5 md:h-10 md:w-10 md:p-2 my-auto bg-green-300 rounded-lg"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                                                    />
                                                </svg>
                                            </a>
                                        </span>
                                        <span
                                            v-else
                                            class="flex flex-col"
                                        >
                                            <a
                                                class="flex flex-row"
                                                href="tel:+8554177001"
                                            >US: 1-855-417-7001
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="white"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="1.5"
                                                    stroke="currentColor"
                                                    class="h-5 w-5 md:h-10 md:w-10 md:p-2 my-auto bg-green-300 rounded-lg"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                                                    />
                                                </svg>
                                            </a>
                                            <a
                                                class="flex flex-row"
                                                href="tel:+8557391702"
                                            >CA: 1-855-739-1702
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="white"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="1.5"
                                                    stroke="currentColor"
                                                   class="h-5 w-5 md:h-10 md:w-10 md:p-2 my-auto bg-green-300 rounded-lg"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                                                    />
                                                </svg>
                                            </a>
                                        </span>

                                    </span>
                                </div>

                                <!-- <div class="my-4 flex flex-row gap-4">
                                    <div
                                        @click.prevent="handleNeedHelp(false)"
                                        class="flex justify-center content-center border-2 border-transparent bg-rd_red  w-full h-auto mx-auto md:w-[304px] outline-none ring-0 p-4 rounded-lg cursor-pointer"
                                    >
                                        <span class="text-white lg:text-rd_fontsize-22  text-center  font-semibold py-4">Back</span>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div
                            v-else
                            class="w-full h-full items-center font-thin font-open_sans text-lg xl:text-fs_headline_div bg-white  p-8 rounded-lg"
                        >
                            <div class="text lg:text-rd_fontsize-32  ">
                                <span>Sorry, service is not supported in your area.</span>
                                <div class="my-4 flex flex-row gap-4">
                                    <div
                                        @click.prevent="toggleModal()"
                                        class="flex justify-center content-center border-2 border-transparent bg-rd_red  w-full h-auto mx-auto md:w-[304px] outline-none ring-0 p-4 rounded-lg cursor-pointer"
                                    >
                                        <span class="text-white lg:text-rd_fontsize-22  text-center  font-semibold lg:py-4">Try another ZIP code</span>
                                    </div>
                                    <div
                                        @click.prevent="handleNeedHelp(true)"
                                        class="flex justify-center content-center border-2 border-transparent bg-rd_red  w-full h-auto mx-auto md:w-[304px] outline-none ring-0 p-4 rounded-lg cursor-pointer"
                                    >
                                        <span class="text-white lg:text-rd_fontsize-22  text-center  font-semibold py-4">Need Help?</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
    import { ref } from 'vue'
    import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import { getCountry, getState } from '../../pages/forms/getLocation'

    export default {
        components: {
            Dialog,
            DialogOverlay,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
        },
        props: ['showNotFoundModal', "toggleNotFoundModal", 'serviceType', 'locationErrorMsg'],
        setup() {
            const getUserCountry = getCountry()

            let userCountry = "Unknown"
            if (getUserCountry && /United States/gi.test(getUserCountry)) {
                userCountry = "United States"
            }
            else if (getUserCountry && /Canada/gi.test(getUserCountry)) {
                userCountry = "Canada"
            }
            console.log('mounted NotFound modal', getUserCountry)
            const needHelpPrompt = ref(false)
            return {
                needHelpPrompt,
                userCountry,
            }
        },
        methods: {
            toggleModal() {
                this.toggleNotFoundModal()
            },
            handleNeedHelp(isOpen) {
                console.log('isOpen ', isOpen)
                this.needHelpPrompt = isOpen
            }
        },
        mounted() {
            document.documentElement.style.padding = null
            document.documentElement.style.overflow = null
        },
        beforeUnmount() {
            document.documentElement.style.padding = null
            document.documentElement.style.overflow = null
        }
    }
</script>
